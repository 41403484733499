<template>
  <div>
    <!-- 頭部組件 -->
    <nav-header></nav-header>

    <div class="app-container login">
      <div class="login-box">
        <div class="login-box-div">
          <h1 class="login-box-div-h1">考生登入</h1>
          <div class="login-box-div-input">
            <p>帳號</p>
            <input type="text" v-model="username" placeholder="請輸入您的准考證號碼" @keyup.enter="login">
          </div>
          <div class="login-box-div-input">
            <p>密碼</p>
            <div class="input-div">
              <input v-model="password" :type="passwordType" placeholder="請輸入您的身分證字號" @keyup.enter="login">
              <span @click="passwordType = passwordType == 'password' ? 'type' : 'password'">
                <img v-if="passwordType == 'type'" src="@/assets/image/icon/eye.png" alt="">
                <img v-else src="@/assets/image/icon/eye-no.png" alt="">
              </span>
            </div>
          </div>
          <div class="login-box-div-rules">
            <div v-show="validatorStatus == 1">
              <img src="@/assets/image/login/error.png" alt="error">
              帳號或密碼輸入錯誤！
            </div>
            <div v-show="validatorStatus == 2">
              <img src="@/assets/image/login/error.png" alt="error">
              帳號或密碼輸入錯誤！
            </div>
            <div v-show="validatorStatus == 3">
              <img src="@/assets/image/login/error.png" alt="error">
              帳號不能為空！
            </div>
            <div v-show="validatorStatus == 4">
              <img src="@/assets/image/login/error.png" alt="error">
              密碼不能為空！
            </div>
          </div>
          <div class="login-box-div-button" @click="login" v-loading="btnLoading">登入</div>
        </div>
        <div class="login-box-img">
          <img src="@/assets/image/login/jept.png" alt="jept">
        </div>
      </div>
    </div>

    <!-- 底部組件 -->
    <nav-footer></nav-footer>
  </div>
</template>

<script>
import store from '@/store'
import { mapGetters } from "vuex"
import { userLogin, questionLoadExam } from "@/request/api"
export default {
  name: 'Login',
  data() {
    return {
      username: '',
      password: '',
      // 0无验证或者验证通过  1账号错误  2密码错误  3賬號為空  4密碼為空
      validatorStatus: 0,
      btnLoading: false,
      passwordType: 'password'
    }
  },
  computed: {
    ...mapGetters(['entryLevel']),
  },
  methods: {
    // 登录
    login() {
      // 驗證賬號是否為空
      if (this.username == '' || this.username == null || this.username == undefined) {
        return this.validatorStatus = 3
      }
      // 驗證密碼是否為空
      if (this.password == '' || this.password == null || this.password == undefined) {
        return this.validatorStatus = 4
      }
      this.btnLoading = true
      userLogin({
        username: this.username,
        password: this.password
      }).then(res => {
        const {data, smg, status} = res
        if (status == 200) {
          this.validatorStatus = 0
          window.sessionStorage.setItem('userInfo', JSON.stringify(data.user_info))
          window.sessionStorage.setItem('exam_level', data.user_info.exam_level)
          window.sessionStorage.setItem('exam_id', data.user_info.exam_id)
          window.sessionStorage.setItem('token', data.token)
          
          // 设置考试等级 准考证号码
          store.commit('user/SET_ADMISSIONTICKET', data.user_info.exam_id)
          store.commit('user/SET_ENTRYLEVEL', data.user_info.exam_level)
          // 设置agora用户id
          store.commit('agora/SET_AGORAUID', data.user_info.id)
          window.sessionStorage.setItem('agoraUid', data.user_info.id)
          // 设置agora房间号
          store.commit('agora/SET_AGORACHANNEL', data.user_info.classroom_no)
          window.sessionStorage.setItem('agoraChannel', data.user_info.classroom_no)
          // 设置考場類型 1正式 2仿真模擬 3練習模擬
          store.commit('exam/SET_EXAMINATIONROOMTYPE', data.examination_room.type)
          window.sessionStorage.setItem('examinationRoomType', data.examination_room.type)



          // 验证之前是否登录  -1未开始考试  
          if (data.user_info.exam_type == 0) {
            this.$router.push({
              path: '/home'
            })
          } else {
            // 考試等級判斷
            // 1-4級只有聽力測驗 閱讀測驗
            // 5-6級只有聽力測驗 閱讀測驗 寫作測驗
            // 7-8級只有聽力測驗 閱讀測驗 寫作測驗 口說測驗
            // 获取答题状态  跳转到对应的页面
            // type -1硬体检测完成  0开始考试  1听力测验  2阅读测验  3写作测验  4口说测验
            // status 1进行中  2已完成
            // type=0 考试已开始 硬体检测未完成
            // type=-1 硬体检测已完成 并听力测验未开始
            // type=1 status=1 听力测验进行中
            // type=1 status=2 听力测验已结束 并阅读测验未开始
            // type=2 status=1 阅读测验进行中
            // type=2 status=2 阅读测验已结束 并写作测验未开始
            // type=3 status=1 写作测验进行中
            // type=3 status=2 写作测验已结束 并口说测验未开始
            // type=4 status=1 口说测验进行中
            // type=4 status=2 口说测验已结束 并考试已结束开始
            questionLoadExam().then(res => {
              if (res.data.type == 1) {
                if (res.data.status == 1) {
                  this.$router.push({ path: '/hearingExam' })
                } else {
                  this.$router.push({ path: '/readExam' })
                }
              } else if (res.data.type == 2) {
                if (res.data.status == 1) {
                  this.$router.push({ path: '/readExam' })
                } else {
                  // 閱讀測驗完成 1-4級跳考試結束 5-8級跳寫作頁面
                  if ([1, 2, 3, 4].includes(Number(this.entryLevel))) {
                    this.$router.push({ path: '/examinationOver' })
                  } else {
                    this.$router.push({ path: '/writingExam' })
                  }
                }
              } else if (res.data.type == 3) {
                if (res.data.status == 1) {
                  this.$router.push({ path: '/writingExam' })
                } else {
                  // 寫作測驗完成 1-6級跳考試結束 7-8級跳口說頁面
                  if ([1, 2, 3, 4, 5, 6].includes(Number(this.entryLevel))) {
                    this.$router.push({ path: '/examinationOver' })
                  } else {
                    this.$router.push({ path: '/reciteExam' })
                  }
                }
              } else if (res.data.type == 4) {
                if (res.data.status == 1) {
                  this.$router.push({ path: '/reciteExam' })
                } else {
                  this.$router.push({ path: '/examinationOver' })
                }
              } else if (res.data.type == -1) {
                this.$router.push({ path: '/hearingExam' })
              } else {
                this.$router.push({ path: '/privacyDetection' })
              }
            })
          }
        } else if (status == 4001) { // 账号错误
          this.validatorStatus = 1
        } else if (status == 4002) { // 密码错误
          this.validatorStatus = 2
        } else if (status == 4004) { // 無此測驗
          this.$router.push({
            path: '/period',
            replace: true
          })
        } else {
          this.$message({
            message: msg || '請求異常，請聯繫管理員處理！',
            type: 'error',
            duration: 5 * 1000
          })
        }
      }).finally(() => {
        this.btnLoading = false
      })
    }
  }
}
</script>

<style lang="less" scoped>
.login {
  background-image: url(../../assets/image/login/loginback.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position-y: 60px;
  display: flex;
  align-items: center;
  .login-box {
    margin: 0 auto;
    width: 1120px;
    position: relative;
    display: flex;
    .login-box-div {
      z-index: 2;
      width: 350px;
      margin: 70px 0 100px 80px;
      padding: 60px 60px;
      background: #FFFFFF;
      border-radius: 16px;
      border: 1px solid #DFE1E8;
      box-shadow: 0px 30px 53px -11px rgba(92, 102, 117, 0.1);
      .login-box-div-h1 {
        margin-bottom: 30px;
        font-family: "Microsoft JhengHei","微軟正黑體";
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        line-height: 53px;
        color: #344054;
        text-align: center;
      }
      .login-box-div-input {
        width: 100%;
        p {
          margin: 20px 0 8px;
          font-family: "Microsoft JhengHei","微軟正黑體";
          font-style: normal;
          font-weight: 400;
          font-size: 24px;
          line-height: 32px;
          color: #8C98A9;
        }
        input {
          width: 100%;
          padding: 0 17px;
          height: 60px;
          font-family: "Microsoft JhengHei","微軟正黑體";
          font-style: normal;
          font-weight: 400;
          font-size: 26px;
          line-height: 35px;
          color: #000000;
          border: 2px solid #E0E7FF;
        }
        .input-div {
          width: 100%;
          height: 57px;
          position: relative;
          input {
            padding-right: 45px;
          }
          span {
            cursor: pointer;
            position: absolute;
            top: 16.5px;
            right: 16px;
            width: 24px;
            height: 24px;
          }
        }
      }
      .login-box-div-rules {
        margin: 10px 0 50px;
        height: 20px;
        div {
          display: flex;
          align-items: center;
          font-family: "Microsoft JhengHei","微軟正黑體";
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          color: #CE3C36;
          img {
            margin-right: 8px;
            width: 26px;
            height: 26px;
          }
        }
      }
      .login-box-div-button {
        cursor: pointer;
        user-select: none;
        width: 100%;
        height: 65px;
        background: #0C4FA2;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        font-family: "Microsoft JhengHei","微軟正黑體";
        font-style: normal;
        font-weight: 700;
        font-size: 30px;
        line-height: 40px;
        color: #FFFFFF;
      }
    }
    .login-box-img {
      position: absolute;
      top: calc(50% - 313px);
      left: 490px;
      width: 804px;
      height: 626px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>